<template>
    <div class="page bg-white" id="resourceQuality">
        <el-row :gutter="20" class="m_b1">
            <el-col :span="4">
                <el-input size="small" clearable v-model.trim="searchForm.resourceNameOrId"
                          maxlength="66" placeholder="资源名称或编号"></el-input>
            </el-col>
            <el-col :span="4">
                <el-select size="small" v-model="searchForm.resourceType" placeholder="资源类型">
                    <el-option
                            v-for="item in hasiudhf"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="6">
                <el-date-picker
                        style="width: 100%"
                        size="small"
                        v-model="searchDates"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-col>
            <el-col :span="6">
                <el-button type="primary" @click="submitForm('searchForm')" size="small"
                           icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetForm('searchForm')" size="small"
                           icon="el-icon-refresh-right">
                    重置
                </el-button>
            </el-col>
        </el-row>
        <el-row class="pa-top">
            <el-col :span="15">
                <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="atlGetDataList"></AdvancedQuery>
            </el-col>
            <el-col :span="9" class="text_right">
                <el-button class="marRight" size="small" v-show="hasPermission('quality:list:test')"
                           :disabled="DataListSelections.length <= 0" @click="quality()">质量检测
                </el-button>
                <el-button-group class="m_r1">
                    <el-button size="small" plain icon="el-icon-refresh-left"
                               @click="resetForm"></el-button>
                    <el-button size="small" plain icon="el-icon-setting"
                               @click="setShow = true"></el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                ref="filterTable"
                size="small"
                height="calc(100vh - 290px)"
                @selection-change="selectionChangeHandle"
                class="table">
            <el-table-column
                    type="selection"
                    width="55">
            </el-table-column>
            <el-table-column v-for="(item,index) in columnList" :key="index"
                             :prop="item.fieldEname" min-width="160"
                             show-overflow-tooltip sortable
                             :label="item.fieldName">
                <template slot-scope="scope">
                        <span v-if="item.fieldEname=='resource_type'">
                            {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                        </span>
                    <span v-else-if="item.fieldEname=='resource_state'">
                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                        </span>
                    <span v-else-if="item.fieldEname=='on_collection'">
                            {{scope.row.on_collection ===  0 ? '否' : '是'}}
                        </span>
                    <span v-else-if="item.fieldEname=='testing_res'">
                            {{scope.row.testing_res == 0 ? "通过" : scope.row.testing_res == 1 ? "异常" : "未检测"}}
                        </span>
                    <span v-else>{{scope.row[item.fieldEname]}}</span>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    width="200"
                    label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain v-show="hasPermission('quality:list:test')"
                               @click="quality(scope.row.id)">质量检测
                    </el-button>
                    <el-button size="mini" type="primary" plain
                               v-show="hasPermission('quality:list:download')"
                               :disabled="scope.row.test_id == null"
                               @click="viewReport(scope.row)">下载报告
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!--质量检测-->
        <QualityInspection ref="qualityInspection" @chengGong="getFileList()"></QualityInspection>
        <!--查看报告-->
        <PdfSee ref="pdfSee"></PdfSee>

    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import QualityInspection from './assembly/qualityInspection' //质量检测
    import PdfSee from './assembly/pdfSee' //查看报告
    export default {
        name: "resourceQuality",
        components: {DraggablePop, AdvancedQuery, QualityInspection, PdfSee},
        data() {
            return {
                searchForm: {
                    resourceNameOrId: '',
                    resourceType: '',
                    beginDate: '',
                    endDate: '',
                },
                hasiudhf: [],
                searchDates: [],
                dataList: [
                    {
                        name: 1,
                    },
                ],
                loading: false,
                DataListSelections: [],//多选数据
                moduleId: "984494659931734016",
                setShow: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,

                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
                searchTpye: 0,
                columnList: [],
            }
        },
        mounted() {
            this.getFileList();
            this.hasiudhf = this.$dictUtils.getDictList('resource_type');
            this.hasiudhf.unshift({label: '全部', value: '',})
        },
        watch: {
            searchDates() {
                if (this.searchDates) {
                    this.searchForm.beginDate = this.searchDates[0]
                    this.searchForm.endDate = this.searchDates[1]
                } else {
                    this.searchForm.beginDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
        methods: {
            //获取资源列表
            getFileList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    return
                }
                this.searchTpye = 0
                this.loading = true;
                this.$axios(this.api.rcPage.testList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'resourceNameOrId': this.searchForm.resourceNameOrId,
                    'resourceType': this.searchForm.resourceType,
                    'startTime': this.searchForm.beginDate,
                    'endTime': this.searchForm.endDate,
                }, 'get').then(res => {
                    if (res.status) {
                        this.loading = false;
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total);
                    }
                })
            },

            //重置
            resetForm() {
                this.onScene = false
                this.pageNo = 0
                this.searchForm.resourceNameOrI = '';
                this.searchForm.resourceType = '';
                this.searchDates = []
                this.searchForm = []
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                // this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.getFileList()
                })
            },

            //查询
            submitForm(formName) {
                this.pageNo = 1;
                this.getFileList();
            },

            //质量检测
            quality(resourceId) {
                let ids = []
                if (resourceId != null) {
                    ids.push(resourceId)
                } else {
                    ids = this.DataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$refs.qualityInspection.init(ids);
            },

            //查看报告
            viewReport(row) {
                this.$confirm('此操作将下载该报告, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //this.$refs.pdfSee.init();
                    this.$axios(this.api.rcPage.viewReport, {
                        //这两个参数都要做判断改
                        resourceId: row.id,
                    }, 'get', 'blob').then(res => {
                        console.log(res)
                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
                        // let filename = res.headers['content-disposition']
                        row.resource_name = row.resource_name != null && row.resource_name != '' ? row.resource_name : row.id
                        let filename = row.resource_name + "-检测报告";
                        let link = document.createElement('a')

                        link.download = decodeURI(filename)
                        console.log(blob, "====", link)
                        link.href = window.URL.createObjectURL(blob)
                        link.setAttribute('download', filename + '.xlsx')
                        link.click()
                    })
                }).catch(() => {
                });
            },

            // 多选
            selectionChangeHandle(val) {
                this.DataListSelections = val
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.getFileList();
                }
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.getFileList();
                }
            },
            // 高级查询
            atlGetDataList(data) {
                this.pageNo = 0
                this.getDataList(data)
            },

            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 1
                }
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.pageSize
                data.current = this.pageNo
                data.searchNumOrName = this.searchForm.resourceNameOrId
                data.type = this.searchForm.resourceType
                data.startTime = this.searchForm.beginDate
                data.endTime = this.searchForm.endDate
                this.$axios(this.api.rcPage.getQualityInspection, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.dataList = res.data.records;
                        this.total = parseInt(res.data.total);
                    }
                })
            },

            // 设置
            setTb() {
                this.setShow = true;
            },

            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.columnList = data;
                this.getFileList();
                this.setShow = state;
                this.$nextTick(() => {
                    this.$refs.filterTable.doLayout()
                })
            },
        }
    }
</script>

<style scoped>

</style>
