<template>
    <el-dialog
            title="查看报告"
            :visible.sync="handleDialogVisible"
            width="50%"
            :append-to-body="true"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <div>
            <pdf :src="file" :page="currentPage"
                 @num-pages="pageCount=$event"
                 @page-loaded="currentPage=$event"
                 @loaded="loadPdfHandler">
            </pdf>
            <p class="text_center m_b1">
                        <span @click="changePdfPage(0)">
                            <i class="el-icon-caret-left pointer" style="font-size: 20px"></i>
                        </span>
                <span style="position: relative;bottom: 2px">{{currentPage}} / {{pageCount}}</span>
                <span @click="changePdfPage(1)">
                            <i class="el-icon-caret-right pointer" style="font-size: 20px"></i>
                        </span>
            </p>
        </div>
    </el-dialog>
</template>

<script>
    import pdf from "vue-pdf"
    export default {
        name: "pdfSee",
        components: {pdf},
        data() {
            return {
                handleDialogVisible: false,
                file:'https://bwgcs.zydbai.com/image/imgaeUploading/922587274279911424/activity/2022/1/陈颖婷说明书.pdf',
                currentPage: 0, // pdf文件页码
                pageCount: 0, // pdf文件总页数
            }
        },
        methods: {
            handleClose() {
                this.handleDialogVisible = false;
            },

            init(){
                this.handleDialogVisible = true;
            },

            // pdf加载时
            loadPdfHandler(e) {
                this.currentPage = 1 // 加载的时候先加载第一页
            },

            // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
            changePdfPage(val) {
                if (val === 0 && this.currentPage > 1) {
                    this.currentPage--
                }
                if (val === 1 && this.currentPage < this.pageCount) {
                    this.currentPage++
                }
            },
        }
    }
</script>

<style scoped>

</style>