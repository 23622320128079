<template>
    <el-dialog
            title="质量检测"
            :visible.sync="handleDialogVisible"
            width="70%"
            :append-to-body="true"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <div class="head">
            <el-steps align-center :active="step">
                <el-step title="检测设置"></el-step>
                <el-step title="质量检测"></el-step>
                <el-step title="检测报告"></el-step>
            </el-steps>
        </div>
        <div v-show="step==0" class="jindu" style="text-align: center" align-center>
            <span>检测方案：</span>
            <el-select style="margin-right: 20px" size="small" v-model="searchForm.select" placeholder="请选择">
                <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.planName"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" @click="schemeSetting('edit')"
                       :disabled="!searchForm.select"
                       size="small">方案设置
            </el-button>
            <el-button type="primary" @click="addProgram('add')" size="small">新增方案</el-button>
        </div>
        <div v-show="step==1" class="jingdu">
            <!--            :status="jingWenzi"-->
            <el-progress :text-inside="true" :stroke-width="20"
                         :percentage="progress"></el-progress>
        </div>
        <div v-show="step==2">
            <el-row class="magin" :gutter="20">
                <el-col :span="8">
                    <div>检测时间：{{ detectionTime }}</div>
                </el-col>
                <el-col :span="8">
                    <div>检测人员：{{ testingPersonnel }}</div>
                </el-col>
            </el-row>
            <div class="flex_l_c tips">
                <div class="el-icon-document"></div>
                <div>
                    <div>检测通过条数: <span class="greenColor">{{ passBar }}条</span></div>
                    <div>检测异常条数: <span class="greenColor">{{ abnormal }}条</span></div>
                </div>
            </div>
            <div class="bg-white top">
                <el-menu :default-active="tabIndex" @select="handleSelectTab" class="el-menu-demo" mode="horizontal">
                    <el-menu-item index="1">全部</el-menu-item>
                    <el-menu-item index="2">未通过</el-menu-item>
                </el-menu>
                <el-table
                        :data="dataList"
                        v-loading="loading"
                        ref="filterTable"
                        size="small"
                        height="calc(300px)"
                        class="table">
                    <el-table-column
                            prop="resourceId"
                            show-overflow-tooltip sortable
                            label="资源编号">
                    </el-table-column>
                    <el-table-column
                            prop="resourceName"
                            show-overflow-tooltip sortable
                            label="资源名称">
                    </el-table-column>
                    <el-table-column
                            prop="testingRes"
                            show-overflow-tooltip sortable
                            label="检测状态">
                        <template slot-scope="scope">
                            {{scope.row.testingRes == 0 ? '通过' : '异常'}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            width="200"
                            label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" plain @click="details(scope.row)">资源详情
                            </el-button>
                            <!--<el-button size="mini" type="primary" plain @click="testing(scope.row)">详情检测
                            </el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="center"
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        :pager-count="5"
                        background
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
        <div style="text-align: center;margin-top: 20px">
            <el-button type="primary" v-if="step==0" @click="next" size="small">开始检测</el-button>
            <!--            <el-button type="primary" v-if="step==1" @click="next" size="small">下一步</el-button>-->
            <el-button type="primary" v-if="step==1" @click="progress == 100 ? next() : ''" size="small" :disabled="progress != 100">下一步</el-button>
<!--            <el-button type="primary" v-show="step==1" @click="next" size="small" disabled>下一步</el-button>-->
            <el-button type="primary" v-if="step==2" @click="again" size="small">重新检测</el-button>
            <el-button type="primary" v-if="step==2" @click="saveTest" size="small">确认</el-button>
        </div>

        <!--三维资源查看-->
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>
        <!--其它查看-->
        <ViewForm ref="viewForm"></ViewForm>
        <!-- 检测方案详情-->
        <SchemeSetting ref="schemeSetting" @schemeDeletion="jianceguanbi"></SchemeSetting>
        <!--查看报告-->
        <PdfSee ref="pdfSee"></PdfSee>
    </el-dialog>
</template>

<script>
    import ViewThreeForm from '../../gather/resource/three/viewThreeForm' // 三维查看弹窗
    import ViewForm from '../../gather/resource/components/viewForm' // 查看弹窗
    import SchemeSetting from './schemeSetting' // 检测方案详情
    import PdfSee from './pdfSee' //查看报告
    export default {
        name: "qualityInspection",
        components: {ViewThreeForm, ViewForm, SchemeSetting, PdfSee},
        data() {
            return {
                handleDialogVisible: false,
                step: 0,
                searchForm: {
                    select: '',
                },
                moduleId: '984494659931734016',   // 当前请求拖拽排序数据id
                progress: 0,
                jingWenzi: '',
                detectionTime: '2022-01-01 12:12:12',
                testingPersonnel: '系统管理员',
                passBar: '',
                abnormal: '',
                tabIndex: '1',
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataList: [],
                ids: [],
                loading: false,
                options: [],
                // 头部信息
                resourcesTestHeadInfo: {},
                // 错误集合
                resourcesTestErrorList: [],
                // 全部集合
                resourcesTestAllList: [],
                timer:'',
            }
        },
        methods: {

            //获取资源列表
            getProgrammeList() {
                this.loading = true;
                this.$axios(this.api.rcPage.programmeList, {}, 'get').then(res => {
                    if (res.status) {
                        this.loading = false;
                        this.options = res.data.records
                    }
                })
            },

            //弹窗关闭
            handleClose() {
                this.handleDialogVisible = false;
                this.again();
            },

            init(ids) {
                this.handleDialogVisible = true;
                this.ids = ids
                this.getProgrammeList()
            },

            //方案设置
            schemeSetting(val) {
                this.$refs.schemeSetting.init(this.searchForm.select, val);
            },
            //新增方案
            addProgram(val) {
                this.$refs.schemeSetting.init(null, val);
            },

            //下一步
            next() {
                if (this.step == 0) {
                    if (this.searchForm.select == '') {
                        this.$message.warning('请先选择检测方案')
                    } else {
                        this.step++;
                        this.loadingProgress();
                    }
                } else {
                    this.step++;
                    if (this.step == 2) {
                        this.getTestHeadInfo()
                        this.getTestAllList()
                    }
                }
            },

            //重新检测
            again() {
                this.step = 0;
                this.searchForm.select = '';
                this.progress = 0;
                this.tabIndex = '1';
                clearInterval(this.timer)
            },

            //进度条加载
            loadingProgress() {
                //进度加载(网速慢)
                this.timer = setInterval(() => {
                    let situation = '';
                    situation = Math.floor(Math.random() * (0 - 10) + 10);
                    this.progress += situation;
                    if (this.progress > 93) {
                        clearInterval(this.timer)
                        //this.jingWenzi = 'success';
                        //this.progress = 100;
                    }
                }, 500)
                //进度请求
                // this.$axios(this.api.assembly.save + '?listKey=' + this.listKey + '&recordKey=' + this.recordKey, {}, 'post').then(res => {
                //     if (res.status) {
                //         clearInterval(timer)
                //         this.jingWenzi = 'success';
                //         this.progress = 100;
                //     } else {
                //         clearInterval(timer)
                //         this.jingWenzi = 'exception';
                //     }
                // })
                console.log(this.searchForm.select, "----", this.ids)
                if (this.ids.length > 0) {
                    this.$axios(this.api.rcPage.programmeStart + this.searchForm.select, this.ids, 'post').then(res => {
                        if (res.status) {
                            this.resourcesTestHeadInfo = res.data[0]
                            this.resourcesTestErrorList = res.data[1]
                            this.resourcesTestAllList = res.data[2]
                            clearInterval(this.timer)
                            this.jingWenzi = 'success';
                            this.progress = 100;
                        } else {
                            this.step = 1
                            clearInterval(this.timer)
                            this.jingWenzi = 'exception';
                        }
                    })
                }
            },
            // 头部信息
            getTestHeadInfo() {
                this.$axios(this.api.rcPage.getTestHeadInfo, {
                    headKey: this.resourcesTestHeadInfo
                }, 'get').then(res => {
                    if (res.status) {
                        this.detectionTime = res.data.time
                        this.testingPersonnel = res.data.testUser
                        this.passBar = res.data.success
                        this.abnormal = res.data.error
                    }
                })
            },
            // 全部列表信息
            getTestAllList() {
                let key = this.tabIndex == 1 ? this.resourcesTestAllList : this.resourcesTestErrorList
                this.$axios(this.api.rcPage.getTestList, {
                    allOrErrorKey: key,
                    current: this.pageNo,
                    size: this.pageSize
                }, 'get').then(res => {
                    if (res.status) {
                        this.total = parseInt(res.data.total)
                        this.dataList = res.data.recode
                        this.$nextTick(() => {
                            this.$refs.filterTable.doLayout(); // 解决表格错位
                        });
                    } else {
                        this.total = 0
                        this.dataList = []
                    }
                })
            },
            // 保存
            saveTest() {
                this.$axios(this.api.rcPage.saveTestInfo, {
                    listKey: this.resourcesTestAllList,
                }, 'get').then(res => {
                    if (res.status) {
                        this.$message.success('保存成功')
                        this.$emit('chengGong')
                        this.handleClose();
                    }
                })
            },

            // tab切换
            handleSelectTab(index) {
                this.tabIndex = index;
                this.getTestAllList();
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.getTestAllList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.getTestAllList();
            },

            //资源详情
            details(row) {
                this.$set(row, 'id', row.resourceId)

                let excludeFieldList = ['testing_res', 'testing_time', 'testing_user']
                if (row.resourceType == 0) {
                    //三维资源
                    this.$refs.viewThreeForm.init(row, excludeFieldList);
                } else {
                    //其它资源
                    this.$refs.viewForm.init(row.id, row.resourceType, this.moduleId, excludeFieldList);
                }
            },

            //详情检测
            testing(row) {
                console.log(row)
                //this.$refs.pdfSee.init();

            },

            //检测关闭
            jianceguanbi() {
                this.getProgrammeList();
                this.searchForm.select = '';
            },
        },
    }
</script>

<style scoped>
    .head {
        margin: 0 100px;
    }

    .jindu {
        margin: 100px 0 100px 50px;
    }

    .jingdu {
        margin: 100px 50px;
    }


    .tips {
        background: #FFEEE6;
        font-weight: 700;
        color: #999999;
        border: 1px solid #FFDDCC;
        border-radius: 5px;
        padding: 20px 15px;
    }

    .tips .el-icon-document {
        margin-right: 20px;
        font-size: 30px;
        color: #FF992F;
    }

    .magin {
        margin: 20px 0 20px 10px;
    }

    .center {
        text-align: center;
    }
</style>
