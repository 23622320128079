<template>
    <el-dialog
            title="检测方案详情"
            :visible.sync="handleDialogVisible"
            width="50%"
            :append-to-body="true"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form size="small" class="query-form" :rules="rules" ref="searchForm" :inline="true" :model="searchForm">
            <el-form-item label="检测方案名称：" prop="planName">
                <el-input size="small" v-model.trim="searchForm.planName" maxlength="20"
                          placeholder="请输入方案名称"></el-input>
            </el-form-item>
            <el-form-item style="width: 40%" label="排序：" prop="planSort">
                <el-input-number :min="1" :max="100"
                                 v-model="searchForm.planSort"></el-input-number>
            </el-form-item>
            <el-button size="small" v-show="searchForm.id"
                       @click="schemeDeletion" type="primary">删除</el-button>
        </el-form>
        <div class="bg-white top">
            <el-menu :default-active="tabIndex" @select="handleSelectTab" class="el-menu-demo" mode="horizontal">
                <el-menu-item v-for="item in $dictUtils.getDictList('testing_range')" :key="item.id"
                              :index="item.id">
                    {{item.label}}
                </el-menu-item>
            </el-menu>
            <!--真实性-->
            <div v-show="tabIndex == tabXuanxxaing[0].id">
                <div v-for="(item1,index1) in authenticity1" :key="item1.id">
                    <div class="title">{{ item1.label }}</div>
                    <div v-for="(item2,index2) in item1.data" :key="item2.title">
                        <el-checkbox class="xhaunzekaung" v-model="item2.select" true-label='1' false-label='0'>
                            {{item2.title}}
                        </el-checkbox>

                        <div v-for="item in testTypeList" style="display:inline">
                            <span>
                                {{item.testingItem == item2.title ? item.testingWay : ''}}
                            </span>
                            <span style="display:none">
                                {{item.testingItem == item2.title ? item.id : ''}}
                            </span>
                        </div>

                        <el-row :gutter="20">
                            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="19">
                                <el-table
                                        v-if="item2.biao"
                                        :data="item2.dataList"
                                        v-loading="loading"
                                        size="small"
                                        border
                                        style="width: 100%"
                                        class="table xiaobiao">
                                    <el-table-column
                                            label="序号"
                                            type="index"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            align="center"
                                            v-for="(item3,index3) in item2.header"
                                            :key="item3.prop"
                                            :prop="item3.prop"
                                            :label="item3.name">
                                        <template slot-scope="scope">
                                            <div v-if="item3.tableType==1">{{ scope.row[item3.prop] }}</div>
                                            <div v-if="item3.tableType==2">
                                                <el-select v-if="item3.valueDropDown != true"
                                                           size="small"
                                                           @change="xuanzhongtiaomu($event,index1,index2)"
                                                           v-model="scope.row[item3.prop]" placeholder="请选择">
                                                    <el-option
                                                            v-for="item in columnList[index2]"
                                                            :key="item.id"
                                                            :label="item.fieldName"
                                                            :disabled="item.disabled"
                                                            :value="item.id"
                                                    >
                                                    </el-option>
                                                </el-select>
                                                <el-select v-if="item3.valueDropDown == true"
                                                           size="small"
                                                           v-model="scope.row[item3.prop]" placeholder="请选择">
                                                    <el-option
                                                            v-for="item in typeList"
                                                            :key="item.value"
                                                            :label="item.label"
                                                            :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div v-if="item3.tableType==3">
                                                <el-input size="small" v-model="scope.row[item3.prop]"
                                                          placeholder="请输入"></el-input>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            align="center"
                                            show-overflow-tooltip sortable
                                            label="操作">
                                        <template slot-scope="scope">
                                            <el-button size="mini" type="primary" plain
                                                       @click="afafeqfaad(1,index1,index2,scope.row,scope.$index)">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="5" style="text-align: right">
                                <el-button v-if="item2.biao" size="small" @click="add(1,index1,index2)"
                                           type="primary">
                                    新增
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <!--完整性-->
            <div v-show="tabIndex == tabXuanxxaing[1].id">
                <div v-for="(item1,index1) in authenticity2" :key="item1.id">
                    <div class="title">{{ item1.label }}</div>
                    <div v-for="(item2,index2) in item1.data" :key="item2.title">
                        <el-checkbox class="xhaunzekaung" v-model="item2.select" true-label='1' false-label='0'>
                            {{item2.title}}
                        </el-checkbox>

                        <div v-for="item in testTypeList" style="display:inline">
                            <span>
                                {{item.testingItem == item2.title ? item.testingWay : ''}}
                            </span>
                        </div>

                        <el-row :gutter="20">
                            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="19">
                                <el-table
                                        v-if="item2.biao"
                                        :data="item2.dataList"
                                        v-loading="loading"
                                        size="small"
                                        border
                                        class="table xiaobiao">
                                    <el-table-column
                                            label="序号"
                                            type="index"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            align="center"
                                            v-for="(item3,index3) in item2.header"
                                            :key="item3.prop"
                                            :prop="item3.prop"
                                            width="150"
                                            :label="item3.name">
                                        <template slot-scope="scope">
                                            <div v-if="item3.tableType==1">{{ scope.row[item3.prop] }}</div>
                                            <div v-if="item3.tableType==2">
                                                <el-select size="small" @change="xuanzhongtiaomu($event,index1,index2,2)"
                                                           v-model="scope.row[item3.prop]" placeholder="请选择">
                                                    <el-option
                                                            v-for="item in columnList[6]"
                                                            :key="item.id"
                                                            :disabled="item.disabled"
                                                            :label="item.fieldName"
                                                            :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div v-if="item3.tableType==3">
                                                <el-input size="small" v-model="scope.row[item3.prop]"
                                                          placeholder="请输入"></el-input>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            align="center"
                                            show-overflow-tooltip sortable
                                            label="操作">
                                        <template slot-scope="scope">
                                            <el-button size="mini" type="primary" plain
                                                       @click="afafeqfaad(2,index1,index2,scope.row,scope.$index)">
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="5" style="text-align: right">
                                <el-button v-if="item2.biao" size="small" @click="add(2,index1,index2)"
                                           type="primary">
                                    新增
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <!--可用性-->
            <div v-show="tabIndex == tabXuanxxaing[2].id">
                <div v-for="(item1,index1) in authenticity3" :key="item1.id">
                    <div class="title">{{ item1.label }}</div>
                    <div v-for="(item2,index2) in item1.data" :key="item2.title">
                        <el-checkbox class="xhaunzekaung" v-model="item2.select" true-label='1' false-label='0'>
                            {{item2.title}}
                        </el-checkbox>
                        <div v-for="item in testTypeList" style="display:inline">
                            <span>
                                {{item.testingItem == item2.title ? item.testingWay : ''}}
                            </span>
                        </div>
                        <el-select style="margin-left: 5%" size="small" v-if="item2.downBox" v-model="item2.value"
                                   placeholder="请选择">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>

            <!--安全性-->
            <div v-show="tabIndex == tabXuanxxaing[3].id">
                <div>
                    <div class="title">电子原文检测</div>
                    <div>
                        <el-checkbox class="xhaunzekaung" v-model="virus" true-label='1' false-label='0'>病毒感染检测
                        </el-checkbox>
                        <el-checkbox class="xhaunzekaung" v-model="security" true-label='1' false-label='0'>
                            操作过程安全性检测
                        </el-checkbox>
                    </div>
                </div>
            </div>
            <div class="text_center m_t1">
                <el-button class="m_t1" type="primary" icon="el-icon-check" size="small" plain @click="saveProgramme()"
                           v-noMoreClick>保存
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "schemeSetting",
        data() {
            return {
                handleDialogVisible: false,
                searchForm: {
                    planName: '',
                    planSort: 1,
                    id: 0,
                },

                rules: {
                    planName: [
                        {required: true, message: '请输入方案名称', trigger: 'change'}
                    ],
                    planSort: [
                        {required: true, message: '请输入排序号', trigger: 'change'}
                    ]
                },

                tabIndex: '',
                tabXuanxxaing: [], //检测范围
                authenticity1: [],
                authenticity2: [],
                authenticity3: [],
                isEditOrAdd: 0,
                editList: [],
                loading: false,
                pxNum: 1,

                columnList: [],
                testTypeList: [],

                typeList: [
                    {
                        label: 'STRING-字符型',
                        value: 'STRING'
                    },
                    {
                        label: 'BYTE-字节型',
                        value: 'BYTE'
                    },
                    {
                        label: 'SHORT-短整型',
                        value: 'SHORT'
                    },
                    {
                        label: 'INTEGER-整型',
                        value: 'INTEGER'
                    },
                    {
                        label: 'LONG-长整型',
                        value: 'LONG'
                    },
                    {
                        label: 'FLOAT-浮点型',
                        value: 'FLOAT'
                    },
                    {
                        label: 'CHAR-字符型',
                        value: 'CHAR'
                    },
                    {
                        label: 'DOUBLE-双精度浮点型',
                        value: 'DOUBLE'
                    },
                    {
                        label: 'BOOLEAN-布尔型',
                        value: 'BOOLEAN'
                    },
                ],

                //安全性
                virus: '1',
                security: '1',
                virusId: 0,
                securityId: 0,

                options: [
                    {
                        value: 'pdf',
                        label: 'pdf',
                    },
                    {
                        value: 'doc',
                        label: 'doc',
                    },
                    {
                        value: 'docx',
                        label: 'docx',
                    },
                    {
                        value: 'text',
                        label: 'text',
                    },
                    {
                        value: 'jpg',
                        label: 'jpg',
                    },
                ],
            }
        },
        mounted() {
        },
        methods: {
            init(programId, val) {

                this.handleDialogVisible = true;
                this.tabXuanxxaing = this.$dictUtils.getDictList('testing_range')
                //真实性
                this.authenticity1 = this.$dictUtils.getDictList('testing_obj')
                this.authenticity1.map(item => {
                    if (item.label == "元数据检测") {
                        let value = [
                            {
                                title: '长度检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: true,
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2, //表格类型，1展示，2下拉框，3输入框
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                    {
                                        name: '长度',
                                        prop: 'testingVal',
                                        tableType: 3,
                                    },
                                ],
                                dataList: [
                                    {
                                        /*originalId: 0,
                                        length: 20,*/
                                    },
                                ],
                            },

                            {
                                title: '类型检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: true,
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2,
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                    {
                                        name: '类型',
                                        prop: 'testingVal',
                                        tableType: 2,
                                        valueDropDown: true,
                                    },
                                ],
                                dataList: [
                                    {
                                        /*originalId: 1,
                                        type: '字符型',*/

                                    },
                                ],
                            },

                            {
                                title: '值域检测',
                                biao: true,
                                select: '1',//是否选中，0未选中，1选中
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2,
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                    {
                                        name: '值',
                                        prop: 'testingVal',
                                        tableType: 3,
                                    },
                                ],
                                dataList: [
                                    {
                                        originalId: '',
                                        /*testingVal: '请填写值1，值2',*/
                                    },
                                ],
                            },

                            {
                                title: '有效值范围',
                                select: '1',//是否选中，0未选中，1选中
                                biao: true,
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2,
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                    {
                                        name: '值',
                                        prop: 'testingVal',
                                        tableType: 3,
                                    },
                                ],
                                dataList: [
                                    {
                                        /*originalId: 1,
                                        value: '',*/
                                    },
                                ],
                            },

                            {
                                title: '特殊字符检测',
                                biao: true,
                                select: '1',//是否选中，0未选中，1选中
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2,
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                    {
                                        name: '特殊字符',
                                        prop: 'testingVal',
                                        tableType: 3,
                                    },
                                ],
                                dataList: [
                                    {
                                        /*originalId: 1,
                                        specialCharacters: '',*/
                                    },
                                ],
                            },

                            {
                                title: '重复性检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: true,
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2,
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                ],
                                dataList: [
                                    {
                                        /*originalId: 1,*/
                                    },
                                ],
                            },
                        ]
                        this.$set(item, 'data', value)
                    } else if (item.label == "电子原文检测") {
                        let value = [
                            {
                                title: '来源真实性检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: false,//是否有表
                            },
                            {
                                title: '内容真实性检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: false,//是否有表
                            },
                        ];
                        this.$set(item, 'data', value)
                    }
                })

                //完整性
                this.authenticity2 = this.$dictUtils.getDictList('testing_obj')
                this.authenticity2.map(item => {
                    if (item.label == "元数据检测") {
                        let value = [
                            {
                                title: '元数据项是否完整检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: false,
                            },

                            {
                                title: '必填项检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: true,
                                header: [ //表头
                                    {
                                        name: '元数据名称',
                                        prop: 'originalId',
                                        tableType: 2,
                                        selectList: [
                                            {
                                                value: 1,
                                                label: '项目编号',
                                            },
                                            {
                                                value: 2,
                                                label: '文件序号',
                                            },
                                            {
                                                value: 3,
                                                label: '文物编号',
                                            },
                                        ],
                                    },
                                ],
                                dataList: [
                                    {
                                        /*originalId: 1,*/
                                    },
                                ],
                            },

                            {
                                title: '连续性检测',
                                biao: false,
                                select: '1',//是否选中，0未选中，1选中
                            },
                        ]
                        this.$set(item, 'data', value)
                    } else if (item.label == "电子原文检测") {
                        let value = [
                            {
                                title: '内容完整性检测',
                                select: '1',//是否选中，0未选中，1选中
                                biao: false,//是否有表
                            },
                        ];
                        this.$set(item, 'data', value)
                    }
                })

                //可用性
                this.authenticity3 = this.$dictUtils.getDictList('testing_obj')
                this.authenticity3.map(item => {
                    if (item.label == "元数据检测") {
                        let value = [
                            {
                                title: '元数据可用性检测',
                                select: '1',//是否选中，0未选中，1选中
                                downBox: false,//是否有下拉框
                                value: '',//下拉框的值
                            },
                        ]
                        this.$set(item, 'data', value)
                    } else if (item.label == "电子原文检测") {
                        let value = [
                            {
                                title: '文件格式检测',
                                select: '1',//是否选中，0未选中，1选中
                                downBox: true,//是否有下拉框
                                value: 'pdf',//下拉框的值
                            },
                        ];
                        this.$set(item, 'data', value)
                    }
                })

                this.tabIndex = this.tabXuanxxaing[0].id;
                this.getTableByResourceName();
                this.getTestingTypeList();

                if (val === 'edit') {
                    this.programmeGetById(programId)
                    this.isEditOrAdd = 1;
                }
            },

            // 获取下拉框数据
            getTableByResourceName() {
                this.$axios(this.api.rcPage.getTableByType, {
                    masterTableId: '963391182279278592',
                    pageType: 1
                }, 'get').then(res => {
                    if (res.status) {
                        res.data.map(item => {
                            this.$set(item, 'disabled', false);
                        })
                        // this.columnList = res.data
                        let columnList0 = JSON.parse(JSON.stringify(res.data))
                        let columnList1 = JSON.parse(JSON.stringify(res.data))
                        let columnList2 = JSON.parse(JSON.stringify(res.data))
                        let columnList3 = JSON.parse(JSON.stringify(res.data))
                        let columnList4 = JSON.parse(JSON.stringify(res.data))
                        let columnList5 = JSON.parse(JSON.stringify(res.data))
                        let columnList6 = JSON.parse(JSON.stringify(res.data))
                        this.columnList.push(columnList0, columnList1, columnList2, columnList3, columnList4, columnList5, columnList6)
                    }
                })
            },

            // 获取类型列表
            getTestingTypeList() {
                this.$axios(this.api.rcPage.testingTypeList, {}, 'get').then(res => {
                    if (res.status) {
                        this.testTypeList = res.data.records
                    }
                })
            },

            getWayId(title) {
                let item = this.testTypeList.find(item => {
                    if (item.testingItem === title) {
                        return item.id;
                    }
                })
                return item.id;
            },
            getWayType(title) {
                let item = this.testTypeList.find(item => {
                    if (item.testingItem === title) {
                        return item.wayType;
                    }
                })
                return item.wayType;
            },

            // 回显
            programmeGetById(id) {
                this.$axios(this.api.rcPage.programmeGetById + id, {}, 'get').then(res => {
                    if (res.status) {
                        this.searchForm.id = res.data.id
                        this.searchForm.planName = res.data.planName
                        this.searchForm.planSort = res.data.planSort

                        if (res.data.planItems != null && res.data.planItems.length > 0) {
                            let wzx = 0;

                            // 取消默认行
                            for (let i = 0; i < 6; i++) {
                                this.authenticity1[0].data[i].dataList.splice(0, 1)
                            }
                            // 取消默认行
                            this.authenticity2[0].data[1].dataList.splice(0, 1)

                            res.data.planItems.map((item, index) => {
                                if (item.wayType < 9) {
                                    if (item.wayType < 7) {

                                        if (item.onStart == 1) {
                                            this.authenticity1[0].data[item.wayType - 1].dataList.push({
                                                originalId: item.originalId,
                                                testingVal: item.testingVal,
                                            })
                                        } else {
                                            this.authenticity1[0].data[item.wayType - 1].select = '0'
                                        }
                                        this.$set(this.authenticity1[0].data[item.wayType - 1], 'id', item.id)
                                    }
                                    if (item.wayType == 7) {
                                        this.authenticity1[1].data[0].select = item.onStart === 1 ? '1' : '0'

                                        this.$set(this.authenticity1[1].data[0], 'id', item.id)
                                    } else {
                                        this.authenticity1[1].data[1].select = item.onStart === 1 ? '1' : '0'

                                        this.$set(this.authenticity1[1].data[1], 'id', item.id)
                                    }
                                } else if (item.wayType > 8 && item.wayType < 13) {
                                    if (item.wayType == 9) {
                                        this.authenticity2[0].data[wzx].select = item.onStart === 1 ? '1' : '0'
                                    }
                                    if (item.wayType == 10) {
                                        if (item.onStart == 1) {
                                            this.authenticity2[0].data[1].dataList.push({
                                                originalId: item.originalId,
                                                testingVal: item.testingVal,
                                            })
                                        } else {
                                            this.authenticity2[0].data[1].select = '0'
                                        }
                                        this.$set(this.authenticity2[0].data[1], 'id', item.id)
                                    } else {
                                        wzx++
                                    }
                                    if (item.wayType == 11) {
                                        this.authenticity2[0].data[wzx].select = item.onStart === 1 ? '1' : '0'
                                    }

                                    if (item.wayType == 12) {
                                        this.authenticity2[1].data[0].select = item.onStart === 1 ? '1' : '0'
                                        this.$set(this.authenticity2[1].data[0], 'id', item.id)
                                    } else if (item.wayType != 10) {
                                        this.$set(this.authenticity2[0].data[wzx], 'id', item.id)
                                    }
                                } else if (item.wayType == 13) {
                                    this.authenticity3[0].data[0].select = item.onStart === 1 ? '1' : '0'
                                    this.$set(this.authenticity3[0].data[0], 'id', item.id)
                                } else if (item.wayType == 14) {
                                    if (item.onStart == 1) {
                                        this.authenticity3[1].data[0].value = item.testingVal
                                    } else {
                                        this.authenticity3[1].data[0].select = '0'
                                    }
                                    this.$set(this.authenticity3[1].data[0], 'id', item.id)
                                } else if (item.wayType == 15) {
                                    this.virus = item.onStart == 1 ? '1' : '0'
                                    this.virusId = item.id
                                } else if (item.wayType == 16) {
                                    this.security = item.onStart == 1 ? '1' : '0'
                                    this.securityId = item.id
                                }

                            })
                        }
                        this.aihffndmskj()
                    }
                })
            },

            //循环重置下拉框
            aihffndmskj(){
                this.xuanzhongtiaomu(null,0,1,2)
                for (let i=0;i<=5;i++){
                    this.xuanzhongtiaomu(null,0,i)
                }
            },

            // 新增方案
            saveProgramme() {
                if (this.searchForm.planName != '' && this.searchForm.planName != null &&
                    this.searchForm.planSort != '' && this.searchForm.planSort != null) {

                    // 归纳保存方案参数
                    let planItemDTOS = []

                    this.authenticity1.map(item => {
                        item.data.map(itemb => {
                            let objectData = {
                                testTypeId: this.getWayId(itemb.title),
                                testType: this.getWayType(itemb.title),
                                onStart: itemb.select || '0',
                                originalId: '',
                                testingVal: '',
                                id: itemb.id,
                                isNew: 1,
                                testTitle: itemb.title,
                            }
                            if (itemb.select === '1' && itemb.dataList != null) {
                                itemb.dataList.forEach(itemc => {
                                    objectData.originalId = itemc.originalId
                                    objectData.isNew = itemc.isNew
                                    if (itemc.testingVal != null) {
                                        objectData.testingVal = itemc.testingVal
                                    }
                                    planItemDTOS.push(JSON.parse(JSON.stringify(objectData)))
                                })
                            } else {
                                planItemDTOS.push(JSON.parse(JSON.stringify(objectData)))
                            }
                        })
                    })

                    this.authenticity2.map(item => {
                        item.data.map(itemb => {
                            let objectData = {
                                testTypeId: this.getWayId(itemb.title),
                                testType: this.getWayType(itemb.title),
                                onStart: itemb.select || '0',
                                originalId: '',
                                testingVal: '',
                                id: itemb.id,
                                isNew: 1,
                                testTitle: itemb.title,
                            }
                            if (itemb.select === '1' && itemb.dataList != null) {
                                itemb.dataList.forEach(itemc => {
                                    objectData.originalId = itemc.originalId
                                    objectData.isNew = itemc.isNew
                                    if (itemc.testingVal != null) {
                                        objectData.testingVal = itemc.testingVal
                                    }
                                    planItemDTOS.push(JSON.parse(JSON.stringify(objectData)))
                                })
                            } else {
                                planItemDTOS.push(JSON.parse(JSON.stringify(objectData)))
                            }
                        })
                    })

                    this.authenticity3.map(item => {
                        item.data.map(itemb => {
                            let objectData = {
                                testTypeId: this.getWayId(itemb.title),
                                testType: this.getWayType(itemb.title),
                                onStart: itemb.select || '0',
                                originalId: '',
                                testingVal: itemb.value,
                                id: itemb.id,
                            }
                            planItemDTOS.push(JSON.parse(JSON.stringify(objectData)))
                        })
                    })

                    let objectData15 = {
                        testTypeId: '15',
                        testType: '15',
                        onStart: this.virus || '0',
                        originalId: '',
                        testingVal: '',
                        id: this.virusId
                    }
                    planItemDTOS.push(objectData15)
                    let objectData16 = {
                        testTypeId: '16',
                        testType: '16',
                        onStart: this.security || '0',
                        originalId: '',
                        testingVal: '',
                        id: this.securityId
                    }
                    planItemDTOS.push(objectData16)
                    if (this.isEditOrAdd == 0) {
                        this.$axios(this.api.rcPage.programmeSave, {
                            planName: this.searchForm.planName,
                            planSort: this.searchForm.planSort,
                            planItemDTOS: planItemDTOS
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('保存成功')
                                this.$emit('schemeDeletion')
                                this.getTableByResourceName()
                                this.handleClose()
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    } else {
                        this.$axios(this.api.rcPage.programmeUpdateById, {
                            id: this.searchForm.id,
                            planName: this.searchForm.planName,
                            planSort: this.searchForm.planSort,
                            planItems: planItemDTOS
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('保存成功')
                                this.getTableByResourceName()
                                this.getProgrammeList()
                                this.handleClose()
                                this.$emit('schemeDeletion')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                } else {
                    this.$message({
                        message: '请补充方案名称及排序',
                        type: 'warning'
                    });
                }
            },
            //获取资源列表
            getProgrammeList() {
                this.loading = true;
                this.$axios(this.api.rcPage.programmeList, {}, 'get').then(res => {
                    if (res.status) {
                        this.loading = false;
                        this.options = res.data.records
                    }
                })
            },

            //方案设置关闭
            handleClose() {
                this.isEditOrAdd = 0;
                this.$refs['searchForm'].resetFields();
                this.searchForm.id = '';
                this.columnList.map(item=>{
                    item.map(item2=>{
                        item2.disabled= false;
                    })
                })
                this.handleDialogVisible = false;
            },

            handleSelectTab(index) {
                this.tabIndex = index;
            },

            //删除  num1为真实性，2完整性
            afafeqfaad(num, index1, index2, row, index) {
                if (num == 1) {
                    this.authenticity1[index1].data[index2].dataList.splice(index, 1)
                    if (this.authenticity1[index1].data[index2].dataList.length == 0) {
                        this.authenticity1[index1].data[index2].select = ''
                    }
                } else {
                    this.authenticity2[index1].data[index2].dataList.splice(index, 1)
                    if (this.authenticity2[index1].data[index2].dataList.length == 0) {
                        this.authenticity2[index1].data[index2].select = ''
                    }
                }
                this.xuanzhongtiaomu(null,index1, index2,num)
            },

            //新增 num1为真实性，2完整性
            add(num, index1, index2) {
                if (num == 1) {
                    if (this.authenticity1[index1].data[index2].title == '长度检测') {
                        this.authenticity1[index1].data[index2].dataList.push({
                            originalId: '',
                            testingVal: '',
                            isNew: '0',
                        })
                    } else if (this.authenticity1[index1].data[index2].title == '类型检测') {
                        this.authenticity1[index1].data[index2].dataList.push({
                            originalId: '',
                            testingVal: '',
                            isNew: '0',
                        })
                    } else if (this.authenticity1[index1].data[index2].title == '值域检测') {
                        this.authenticity1[index1].data[index2].dataList.push({
                            originalId: '',
                            testingVal: '',
                            isNew: '0',
                        })
                    } else if (this.authenticity1[index1].data[index2].title == '有效值范围') {
                        this.authenticity1[index1].data[index2].dataList.push({
                            originalId: '',
                            testingVal: '',
                            isNew: '0',
                        })
                    } else if (this.authenticity1[index1].data[index2].title == '特殊字符检测') {
                        this.authenticity1[index1].data[index2].dataList.push({
                            originalId: '',
                            testingVal: '',
                            isNew: '0',
                        })
                    } else if (this.authenticity1[index1].data[index2].title == '重复性检测') {
                        this.authenticity1[index1].data[index2].dataList.push({
                            originalId: '',
                            isNew: '0',
                        })
                    }
                    if (this.authenticity1[index1].data[index2].dataList.length > 0) {
                        this.authenticity1[index1].data[index2].select = '1'
                    }

                } else {
                    if (this.authenticity2[index1].data[index2].title == '必填项检测') {
                        this.authenticity2[index1].data[index2].dataList.push({
                            originalId: '',
                            isNew: '0',
                        })
                        if (this.authenticity2[index1].data[index2].dataList.length > 0) {
                            this.authenticity2[index1].data[index2].select = '1'
                        }
                    }
                }
            },

            //删除方案
            schemeDeletion() {
                this.$confirm('此操作将永久删除该方案, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //删除接口
                    this.$axios(this.api.rcPage.removeTestingPlan+ this.searchForm.id, {}, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('删除成功')
                            this.$emit('schemeDeletion')
                            this.handleClose();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }).catch(() => {
                });
            },

            //下拉框选择
            xuanzhongtiaomu(data, index1, index2,num) {
                if (num==2){
                    this.columnList[6].forEach(item => {
                        item.disabled = false;
                        this.authenticity2[index1].data[index2].dataList.map(item2 => {
                            if (item.id == item2.originalId) {
                                item.disabled = true;
                            }
                        })
                    })
                }else {
                    this.columnList[index2].forEach(item => {
                        item.disabled = false;
                        this.authenticity1[index1].data[index2].dataList.map(item2 => {
                            if (item.id == item2.originalId) {
                                item.disabled = true;
                            }
                        })
                    })
                }
            },
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    .xhaunzekaung {
        margin-top: 10px;
        margin-left: 20px;
    }

    .xiaobiao {
        margin-left: 40px;
        width: 500px;
    }
</style>
